// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-assets-js": () => import("./../../../src/pages/assets.js" /* webpackChunkName: "component---src-pages-assets-js" */),
  "component---src-pages-collaborate-js": () => import("./../../../src/pages/collaborate.js" /* webpackChunkName: "component---src-pages-collaborate-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-examples-js": () => import("./../../../src/pages/examples.js" /* webpackChunkName: "component---src-pages-examples-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-get-support-js": () => import("./../../../src/pages/get-support.js" /* webpackChunkName: "component---src-pages-get-support-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-modes-behind-the-scene-js": () => import("./../../../src/pages/modes/behind-the-scene.js" /* webpackChunkName: "component---src-pages-modes-behind-the-scene-js" */),
  "component---src-pages-modes-installation-js": () => import("./../../../src/pages/modes/installation.js" /* webpackChunkName: "component---src-pages-modes-installation-js" */),
  "component---src-pages-modes-js": () => import("./../../../src/pages/modes.js" /* webpackChunkName: "component---src-pages-modes-js" */),
  "component---src-pages-modes-mode-js": () => import("./../../../src/pages/modes/mode.js" /* webpackChunkName: "component---src-pages-modes-mode-js" */),
  "component---src-pages-modes-submit-form-js": () => import("./../../../src/pages/modes/submit-form.js" /* webpackChunkName: "component---src-pages-modes-submit-form-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-release-notes-3-p-7-js": () => import("./../../../src/pages/release-notes/3p7.js" /* webpackChunkName: "component---src-pages-release-notes-3-p-7-js" */),
  "component---src-pages-release-notes-3-p-8-js": () => import("./../../../src/pages/release-notes/3p8.js" /* webpackChunkName: "component---src-pages-release-notes-3-p-8-js" */),
  "component---src-pages-release-notes-3-p-9-js": () => import("./../../../src/pages/release-notes/3p9.js" /* webpackChunkName: "component---src-pages-release-notes-3-p-9-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-release-notes-previous-js": () => import("./../../../src/pages/release-notes/previous.js" /* webpackChunkName: "component---src-pages-release-notes-previous-js" */),
  "component---src-pages-roadmap-3-p-10-js": () => import("./../../../src/pages/roadmap/3p10.js" /* webpackChunkName: "component---src-pages-roadmap-3-p-10-js" */),
  "component---src-pages-roadmap-archived-js": () => import("./../../../src/pages/roadmap/archived.js" /* webpackChunkName: "component---src-pages-roadmap-archived-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-showcase-template-js": () => import("./../../../src/pages/showcase/template.js" /* webpackChunkName: "component---src-pages-showcase-template-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

