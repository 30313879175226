import "./src/styles/global.css";

const scrollToElement = require("scroll-to-element");

export const onRouteUpdate = ({ location }) => {
  checkHash(location);
};

const checkHash = (location) => {
  let { hash } = location;
  if (hash) {
    scrollToElement(hash, {
      offset: -95,
      duration: 0,
    });
  }
};
